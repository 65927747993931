body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/**background-image: linear-gradient(to right top, #eb9cd3, #dd9cd7, #cf9cd9, #c09cda, #b19cd9); */

.columns {
  background: transparent\9;
  /*background: rgba(255,255,255,0.9);*/
}

.art-container {
  content: '';
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 1vh;
  margin: 1vh;
}

.avatar {
  margin: auto auto;
}

.avatar:before {
  content: '';
  position: absolute;
  box-shadow: inset 0 0 auto rgba(255, 255, 255, 0.5);
  filter: blur(10px);
}

/*.column {
  display: block;
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}*/

.box-separator {}

.box-title {
  overflow-wrap: break-word;
  text-decoration: none;
}

.box-body {}

.box-container {
  content: '';
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 2vh;
  margin: 2vh;
}

.card {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
  border-radius: 5px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.FooterBase {
  display: flex;
  position: relative;
  width: 100%;
  /*background: grey;*/
  height: 100px;
}

.FooterFooter {
  bottom: 0;
  text-align: center;
}

.glance {
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 1;
  transform: translateY(50%);
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-auto-flow: row;
  position: relative;
  text-align: center;
}

.grid-art {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-auto-flow: row;
  position: relative;
  text-align: center;
}

.HeaderContainer {
  background: inherit;
  position: relative;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-left: .5%;
  padding-right: .5%;
}

.HeaderContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-content: center;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur();
  background: inherit;
}

.is-centered {
  -webkit-box-pack: center;
  display: block;
  text-align: center;
  justify-content: center;
  align-content: center;
}

.menu-link {
  text-decoration: none;
}

.navbar-brand {
  -webkit-box-align: stretch;
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-width: 3.25rem;
  margin: 2rem;
}

.ResumeContainer {
  background: inherit;
  position: relative;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: fit-content;
  margin-left: 5%;
  margin-bottom: 5%;
  float: left;
  margin-right: 5%;
  padding-bottom: .5%;
  padding-left: .5%;
  padding-right: .5%;
}

.ResumeContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-content: left;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.4);
  filter: blur();
  background: inherit;
}

.ResumeDate {
  font-weight: lighter;
  font-size: small;
}

.ResumeDescription {
  font-size: medium;
  font-weight: normal;
  color: black;
  margin-top: 2%;
  margin-left: 5%;
}

.ResumeHeader {
  font-weight: bold;
  color: black;
  font-size: x-large;
}

.ResumeSubHeader {
  font-weight: bold;
  color: black;
  margin-top: 2%;
  font-size: large;
}

.Section {
  /*background-color: #aac6fc;*/
  align-content: center;
  display: grid;
  overflow: hidden;
}

.SectionHeader-Blue {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  font-size: x-large;
  padding-bottom: 2em;
  padding-top: 2em;
  /*color: #000;*/
}

.SectionWrapper {}

.Skills {
  font-size: medium;
  font-weight: normal;
  color: black;
  margin-top: 1%;
  margin-left: 5%;
}

.subheader {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

.title {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: xx-large;
}

@media only screen and (max-width: 1000px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid-art {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }
  .grid-art {
    grid-template-columns: 1fr;
  }
}

html, body, #root {
  margin: 0;
  padding: 0;
  /*background-color: white;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: display;
}
